import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ceil from 'lodash/ceil';

import { countryCode, ageRatingBoard, currencyCode } from 'Helpers/Geo/data';
import store from 'Redux/store';
import { itemsToApplyVoucherTo } from 'Reselects/cartcalc';

const ProductRequirements = {};

// Check if sample download is available
ProductRequirements.SampleDownload = (product) => {
  if (!product) return false;

  let sampleDownload = false;
  if (product.downloads && Array.isArray(product.downloads)) {
    sampleDownload = product.downloads.find(download => download.is_sample);
  }

  return sampleDownload;
};

// Check if a product is age gated
ProductRequirements.showAgeGate = (ageRatings) => {
  const reduxStore = store.getState();
  const passedAgeGate = reduxStore.ageGating.success;

  const ESRB_MIN_DISPLAY = 17;
  const USK_MIN_DISPLAY = 16;
  const PEGI_MIN_DISPLAY = 18;
  const ACB_MIN_DISPLAY = 18;

  const chooseMinAgeRating = (board) => {
    switch (board) {
      case 'ESRB':
        return ESRB_MIN_DISPLAY;
      case 'PEGI':
        return PEGI_MIN_DISPLAY;
      case 'USK':
        return USK_MIN_DISPLAY;
      case 'ACB':
        return ACB_MIN_DISPLAY;
      default:
        return 16;
    }
  };

  let ageGatedContent = false;
  const minAgeRating = chooseMinAgeRating(ageRatingBoard);
  if (ageRatings[ageRatingBoard] && ageRatings[ageRatingBoard] >= minAgeRating) {
    ageGatedContent = true;
  }

  if (passedAgeGate) {
    ageGatedContent = false;
  }

  return ageGatedContent;
};

// Check discounts
ProductRequirements.DiscountTime = (product) => {
  if (!product) return false;

  let showDiscountDate = false;
  let discountTime = 0;
  const thresholdTime = 1.21e+9;

  if (product.current_discount) {
    discountTime = Date.parse(product.current_discount.until);
    if ((Date.now() + thresholdTime) > discountTime) {
      showDiscountDate = true;
    }
  }

  return showDiscountDate;
};

// Check if language is no german
ProductRequirements.LanguagesCheck = (product) => {
  if (!product) return false;

  const languages = product.lang;

  let releaseDate = false;
  if (product.release_date) {
    if (new Date(product.release_date).getTime() < Date.now()) {
      releaseDate = true;
    }
  }

  let noGermanRelease = false;
  if (countryCode === 'DE' && (product.no_release_date && !releaseDate)) {
    noGermanRelease = true;
  }

  return ({ languages, noGermanRelease });
};

// Return videos for carousel
ProductRequirements.ProductVideos = (product) => {
  if (!product) return false;

  let productVideos = [];

  if (product.video) {
    productVideos = product.video
      .map(video => ({ src: video, cover: product.cover }));
  }

  return productVideos;
};

// Check release date
ProductRequirements.ReleaseDates = (product) => {
  if (!product) return false;

  let releaseDate = null;

  if (product.release) {
    releaseDate = new Date(product.release).toISOString();
  }

  if (product.release_date) {
    releaseDate = new Date(product.release_date).toISOString();
  }

  let playableFrom = '';
  let earlyAccess = false;
  if (product.preorderPlayableFrom) {
    playableFrom = new Date(product.preorderPlayableFrom).toISOString();

    if (
      new Date(product.release_date).getTime() > Date.now() &&
      new Date(product.preorderPlayableFrom).getTime() < new Date(product.release_date).getTime()
    ) {
      earlyAccess = true;
    }
  }

  const releaseDay = new Date(releaseDate).getDate();
  const releaseMonth = new Date(releaseDate).getMonth();

  const isPreorder = Date.now() < new Date(releaseDate).getTime();

  let hideDate = false;

  if (releaseDay === 31 && releaseMonth === 11 && Date.parse(releaseDate) > Date.now()) {
    hideDate = true;
  }

  let prerelease = false;
  const today = new Date().getTime();
  const releaseTime = new Date(releaseDate).getTime();

  if (releaseTime > today) {
    prerelease = true;
  }

  let earlyAdopterBonus = false;
  if (
    new Date(product.release_date).getTime() <= Date.now() &&
    new Date(product.preorderBonusContinues).getTime() >= Date.now()
  ) {
    earlyAdopterBonus = true;
  }

  return ({
    releaseDate,
    hideDate,
    prerelease,
    isPreorder,
    earlyAdopterBonus,
    earlyAccess,
    playableFrom,
  });
};

// Display if isNintendo
ProductRequirements.ShowNintendo = (product) => {
  if (!product) return false;

  let nintendoProduct = false;
  const validDrm = product.drm ? Object.keys(product.drm).filter(d => product.drm[d]) : '';

  if (includes(validDrm, 'switch') || includes(validDrm, 'threeds')) {
    nintendoProduct = true;
  }


  return nintendoProduct;
};

// Display product requirements
ProductRequirements.ShowRequirements = (product) => {
  if (!product) return false;

  let showRequirements = false;
  let validPlatforms = {};
  let validSystems = {};
  let systemsEntries = [];

  if (product.platforms) {
    systemsEntries = Object.entries(product.platforms);
    systemsEntries.forEach((system) => {
      if (system[1] && product.type !== 'bundle') {
        showRequirements = true;
      }
    });
  }

  validPlatforms = Object.keys(product.drm || {})
    .filter(p => product.drm[p]);

  validSystems = Object.keys(product.platforms || {})
    .filter(s => product.platforms[s]);

  return ({ showRequirements, validPlatforms, validSystems });
};

// Check if drm is valid
ProductRequirements.ValidDrm = (product) => {
  if (!product) return false;

  let drmCheck = true;

  const validDrm = product.drm ? Object.keys(product.drm).filter(d => product.drm[d]) : '';
  if (validDrm[0] === 'origin' || validDrm[0] === 'drm_free') {
    drmCheck = false;
  }

  return drmCheck;
};

// Is the product a preorder
ProductRequirements.isPreorder = (product) => {
  if (product.release_date) {
    const today = new Date().getTime();
    const releaseTime = new Date(product.release_date).getTime();

    if (releaseTime > today) {
      return true;
    }
  }

  return false;
};

// Pull up videos from bundle games
ProductRequirements.AllVideos = (bundles = []) => bundles
  .reduce(
    (acc, tier) => acc.concat(sortBy(tier.games, ['sort_order']).map(game =>
      game.display && game.video[0] && ({ src: game.video[0], cover: game.cover }))),
    [],
  ).filter(video => video);


// Check if url's game type is correct and adjust
ProductRequirements.WrongUrlCheck = (type, slug, urlType, urlSlug) => {
  let isWrongType = false;

  if (urlType !== type) {
    isWrongType = true;
  }

  if (urlSlug && urlSlug !== slug) {
    isWrongType = true;
  }

  return ({ isWrongType, modifiedUrl: `${type}/${slug}` });
};

// Get current drm
ProductRequirements.CurrentDrm = (product) => {
  if (!product || !product.drm) return '';

  const currentDrm = Object.keys(product.drm).filter(d => product.drm[d]);

  return currentDrm[0];
};

/**
 * [validPlatforms Format Platforms in a way the ProductCommerceDetail can display them currently]
 * @param {Object} product [description]
 */
ProductRequirements.validPlatforms = (product) => {
  if (!product.platforms) return false;

  return Object.keys(product.platforms).filter(p => product.platforms[p]);
};


ProductRequirements.CatalinaSupported = (product) => {
  // Cases to approve
  let osxCheck = false;
  let catalinaRecordFlag = false;
  let gameSupportsMac = false;

  // User must be on a Mac
  if (navigator.platform === 'MacIntel') {
    osxCheck = true;
  }

  // Product record check for Catalina supported
  if (product.catalina) {
    catalinaRecordFlag = true;
  }

  // Games system is Mac supported
  if (product.platforms && product.platforms.mac) {
    gameSupportsMac = true;
  }

  if (osxCheck && !catalinaRecordFlag && gameSupportsMac) {
    return true;
  }
  return false;
};

/**
 * returns a coupon that active on the site if it applies to the passed in product
 * @param  {Object} product
 * @param  {Object} voucher  voucher/coupon we are assessing
 * @return {Object}         valid active coupon if there is one
 */
ProductRequirements.validCoupon = (product, voucher) => {
  if (isEmpty(voucher)) return null;

  const { voucherApplyArray } = itemsToApplyVoucherTo({
    voucher,
    pickAndMixAll: store.getState().pickAndMix.all,
    cartItems: [product],
  });

  const hasValidCoupon = !!voucherApplyArray.find(v => v._id === product._id);
  if (hasValidCoupon) return voucher;
  return null;
};

/**
 * work out the total saving on a product including coupons and discounts
 * @param  {Object} product
 * @param  {Object} validCoupon any coupon applied to the passed on product
 * @return {Object}             saving percent and price
 */
ProductRequirements.totalSaving = (product, validCoupon) => {
  // Refactor this function if the split test 161 wins
  let price = {};
  let percent = 0;
  const rrpPrice = product.price[currencyCode];
  let finalPrice = rrpPrice;
  const currentDiscount = product.current_discount;
  let discountPrice = rrpPrice;
  let extraSavingPrice = { [currencyCode]: 0 };
  const isBundle = product.type === 'bundle';
  const currentDiscountPercent = _get(currentDiscount, 'percent', 0);
  const couponDiscountPercent = (_get(validCoupon, 'percent', 0) / 100);
  const hasExtraSaving = (currentDiscountPercent > 0 && currentDiscountPercent <= 1) || isBundle;
  const monetaryAmount = _get(validCoupon, 'amount');
  let isMonetary = false;

  if (currentDiscountPercent > 0 && currentDiscountPercent <= 1 && (product.type !== 'bundle' || product.is_srp_bundle)) {
    discountPrice *= 1 - currentDiscountPercent;
    finalPrice *= 1 - currentDiscountPercent;
  }

  if (couponDiscountPercent > 0 && couponDiscountPercent <= 1) {
    finalPrice *= 1 - couponDiscountPercent;
  }

  if (monetaryAmount && monetaryAmount[currencyCode] > 0) {
    isMonetary = true;
    // finalPrice -= (validCoupon.amount[currencyCode] * 100);
    // placeholder for monetary calculation
  }

  price = { [currencyCode]: Math.round(rrpPrice - finalPrice) };

  if (hasExtraSaving) {
    extraSavingPrice = (isBundle && !product.is_srp_bundle) ?
      price : { [currencyCode]: Math.round(discountPrice - finalPrice) };
  }

  percent = Math.round(((finalPrice - rrpPrice) / rrpPrice) * 100);

  if (product.type === 'bundle' && !product.mystery && !product.is_srp_bundle) {
    const fullPrice = product.fullPrice[currencyCode];
    percent = Math.round(((finalPrice - fullPrice) / fullPrice) * 100);
  }

  return {
    percent, // total percent saved
    price, // the total saved return in a price object
    hasExtraSaving, // does the product have an additional saving beyond the product level discount
    extraSavingPrice, // difference between the discount saving and any additonal savings
    isMonetary,
  };
};

/**
 * returns with if the passed in product is a star deal
 * @param  {Object}  product
 * @return {Boolean}
 */
ProductRequirements.isStarDeal = (product) => {
  if (!product) return false;

  return _get(product, 'current_discount.star_deal', false);
};

/**
 * Works out remaining time left on a stardeal and returns as a percentage
 * @param  {Object}  product
 * @return {Boolean}
 */
ProductRequirements.starDealKeyAvailablePercentage = (product) => {
  const currentDiscount = product.current_discount;

  if (!currentDiscount) return 0;

  const startDate = (new Date(currentDiscount.from).getTime());
  const endDate = (new Date(currentDiscount.until).getTime());

  const oneDay = (24 * 60) * 60;
  let progressPeriod = oneDay * 14; // Timer coundown period set to 14 days
  if (startDate > 0) {
    progressPeriod = (endDate - startDate) / 1000;
  }

  const dealSecondsRemaining = (endDate - Date.now()) / 1000;
  return ceil((dealSecondsRemaining / progressPeriod) * 100);
};

export default ProductRequirements;
